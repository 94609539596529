<template>
  <div class="nr-cartf">
    <div class="center">
      <div class="tit">
        <h2>購買專區</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="step-con">
          <img src="@/assets/img/bc-step-3.png" alt="" />
        </div>
        <h3>交易明細</h3>
        <div class="som" v-html="shoppingRules">
          <!-- <p>親愛的 {{ userInfo.name }} 先生/小姐：</p> -->
          <!-- <p>
            感謝您對 3C簡單購
            的愛護與支持，您的訂購已經完成。我們將立刻寄出您的訂單確認函至您在
            3C簡單購
            登錄的電子郵件信箱中。倘若有任何問題，請依訂單編號向我們查詢，再次感謝您的購買。
          </p>
          <p>
            如您選擇的付款方式為「銀行匯款/ATM轉帳」，請在一星期內匯款，在您將貨款匯至我們的帳戶後，請以E-MAIL、電話或傳真回覆我們您已付款，並註明匯款者(訂購人姓名)、會員帳號、匯款帳戶後5碼及訂單編號。您可在付款之後7天內收到所訂購的商品。
          </p> -->
        </div>
        <CompCartTable
          :cartList="cartList"
          :cartDetailList="cartDetailList"
          :cartTotalList="cartTotalList"
          :strBType="strBType"
          :showDel="false"
        />
        <!-- <div style="max-height: 600px; overflow-y: auto">
          <table>
            <thead>
              <tr>
                <th>商品名稱</th>
                <th style="width: 200px">價格</th>
                <th>數量</th>
                <th style="width: 200px">小計</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(item, index) in cartList" :key="index">
                <td>
                  <img
                    :src="cartDetailList[index].img"
                    alt=""
                    style="height: 88px"
                  />
                  <span>{{ cartDetailList[index].name }}</span>
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        item.value1 | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        item.value2 | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ item.value1 | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{ cartDetailList[index].direct_price | toThousandFilter }}
                </td>
                <td>
                  {{ item.qty }}
                </td>
                <td v-if="!strBType">
                  <template v-if="item.coin1 !== 'T'">
                    <p>
                      {{ item.coin1 | coinName }}：${{
                        (item.value1 * item.qty) | toThousandFilter
                      }}
                    </p>
                    <p>
                      {{ item.coin2 | coinName }}：${{
                        (item.value2 * item.qty) | toThousandFilter
                      }}
                    </p>
                  </template>
                  <template v-else>
                    <p>${{ (item.value1 * item.qty) | toThousandFilter }}</p>
                  </template>
                </td>
                <td v-else>
                  ${{
                    (cartDetailList[index].direct_price * item.qty)
                      | toThousandFilter
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->
        <!-- <div class="pri">
          <div class="l-jg">應繳金額</div>
          <div class="r-jg" v-if="!strBType">
            <template v-if="cartTotalList.length > 0">
              <p v-for="(item, key) in cartTotalList" :key="key">
                <span v-if="item.coin !== 'T'">
                  {{ item.coin | coinName }}：${{
                    item.value | toThousandFilter
                  }}
                </span>
                <span v-else> ${{ item.value | toThousandFilter }} </span>
              </p>
            </template>
            <p v-else>$ 0</p>
          </div>
          <div class="r-jg" v-else>
            <template v-if="cartTotalList.length > 0">
              <p>${{ calculateCartDirectTotal() | toThousandFilter }}</p>
            </template>
            <p v-else>$ 0</p>
          </div>
        </div> -->
        <!-- <div class="tit">
          <strong>付款帳號</strong>
        </div> -->
        <div class="info-list">
          <p>
            如您選擇「銀行匯款 / ATM
            轉帳」，繳費完成請您務必以e-mail、傳真或電話告知我們已付清款項，我們經確認後，會立即出貨，謝謝您。
          </p>
        </div>
        <div class="btn-ct">
          <router-link class="ljjz" to="/home">繼續逛逛</router-link>
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CompCartTable: require("@/components/CartTable").default,
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.member.userInfo,
      shoppingRules: (state) => state.precautions.shoppingRules,
    }),
    ...mapGetters({
      webMode: "webMode",
    }),
  },
  data() {
    return {
      cartList: JSON.parse(localStorage.lastCartList),
      cartDetailList: JSON.parse(localStorage.lastCartDetailList),
      cartTotalList: JSON.parse(localStorage.lastCartTotalList),
      strBType: this.$route.params.strBType * 1,
    };
  },
  async mounted() {
    await this.getShoppingRules()
  },
  updated() {},
  methods: {
    calculateCartDirectTotal() {
      return this.cartDetailList.reduce(
        (obj, next, i) =>
          obj + Number(next.direct_price) * this.cartList[i].qty,
        0
      );
    },
    async getShoppingRules() {
      await this.$store.dispatch("precautions/getShoppingRules");
    },
  },
};
</script>
<style scoped>
@import "~@/assets/css/buy-car-3.css";
</style>
