<template>
  <div class="nr">
    <div class="center">
      <div class="tit">
        <h2>會員中心</h2>
      </div>
      <CompMarquee />
      <div class="ctnr">
        <div class="r-content">
          <h2>會員註冊</h2>
          <p>
            已經是 3C簡單購會員 <router-link to="/login">點此登入</router-link>
          </p>
          <div class="list">
            <h5>所有標有星號（<span>*</span>）的欄位皆為必填</h5>
            <div class="bar">
              <span>會員暱稱<i>*</i></span>
              <input
                v-model="data.name"
                type="text"
                placeholder="輸入您的暱稱"
              />
            </div>
            <div class="bar">
              <span>手機號碼<i>*</i></span>
              <input
                v-model="data.username"
                type="number"
                placeholder="請填寫正確，系統稍後會寄發驗證簡訊。範例：0933123456"
              />
            </div>
            <div class="bar">
              <span>電子信箱<i>*</i></span>
              <input
                v-model="data.email"
                type="text"
                name="email"
                placeholder="請填寫正確，消費後電子發票將寄發至您的信箱"
              />
            </div>
            <div class="bar">
              <span>會員密碼<i>*</i></span>
              <input
                v-model="data.password"
                type="password"
                placeholder="輸入您的密碼"
              />
            </div>
            <div class="bar">
              <span>確認密碼<i>*</i></span>
              <input
                v-model="data.passwordC"
                type="password"
                placeholder="再次輸入您的密碼"
              />
            </div>
            <div class="regRule">
              <input type="checkbox" v-model="bolChkRule" />
              <span @click="rule()"
                >已詳細閱讀並瞭解和接受 3C簡單購
                <a href="javascript:">服務條款</a></span
              >
            </div>
          </div>
          <input
            v-on:click="vodSend()"
            type="button"
            class="sbm"
            value="送  出"
          />
        </div>
      </div>
      <CompFoot />
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    CompMarquee: require("@/components/parts/Marquee").default,
    CompFoot: require("@/components/parts/Foot").default,
  },
  computed: {
    ...mapGetters({
      loginState: "loginState",
    }),
    ...mapState({
      termsOfServiceList: (state) => state.website.termsOfServiceList,
    }),
  },
  data() {
    return {
      objShare: {
        strCode: "",
      },
      data: {
        name: "",
        username: "",
        email: "",
        password: "",
        passwordC: "",
        parent: localStorage.parent || "",
      },
      bolChkRule: false,
      ruleFile: "",
    };
  },
  methods: {

    async getTermsOfServiceList(lottery_id) {
      await this.$store.dispatch("website/getTermsOfServiceList");
    },
    async register() {
      const state = await this.$store.dispatch("member/register", this.data);
      if (state) {
        await this.$store.dispatch("product/removeaAllCartList");
        const { username, password } = this.data;
        await this.$store.dispatch("member/login", { username, password });
      }
    },
    rule() {
      $.fancybox.open({
        caption: this.termsOfServiceList.title,
        src: this.$baseImgUrl + this.termsOfServiceList.url,
        type: "iframe",
        titleShow: true,
        opts: {},
      });
    },
    bolEmailChk(email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    vodSend() {
      if (this.bolChkRule == false) {
        this.$snotify.error("請先勾選 接受服務條款");
        return;
      }
      let objChk = {
        name: "會員暱稱",
        username: "手機號碼",
        email: "電子信箱",
        password: "會員密碼",
        passwordC: "確認密碼",
      };
      for (var a in this.data) {
        if (this.data[a] == "" && a !== "parent") {
          this.$snotify.error("請填寫 " + objChk[a]);
          return;
        }
      }
      if (this.data.password != this.data.passwordC) {
        this.$snotify.error("密碼與確認密碼不符合");
        return;
      }
      if (this.data.password.length >= 4 && this.data.password.length <= 12) {
      } else {
        this.$snotify.error("密碼需介於4-12字之間");
        return;
      }
      if (this.bolEmailChk(this.data.email) == false) {
        this.$snotify.error("email 格式錯誤");
        return;
      }
      const re = /^\d{10}$/;
      if (!re.test(this.data.username)) {
        this.$snotify.error("手機號碼不完整");
        return;
      }
      this.register();
    },
  },
  mounted() {
    this.getTermsOfServiceList();
  },
};
</script>
<style scoped>
@import "~@/assets/css/enroll.css?1.0.2";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
